import { cleanNilValues } from '@manigo/manigo-commons';

import { getPartnerCardTiers } from './index';


class CardTiersService {

    public fetchPartnerCardTiers(rawQueryParams) {
        const { partnerUuid } = rawQueryParams;

        const queryParams = { params: cleanNilValues({ partnerUuid }) };
        return getPartnerCardTiers(queryParams);
    }

}

export default CardTiersService;

