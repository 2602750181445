import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { ToastType } from 'models/app/toast';
import { Epic } from 'models/meta/epic';

import { showToast } from 'store/application/actions';
import { hideModal } from 'store/modal/actions';

import {
    assignAccountSuccess,
    assignAccountFailure,
    fetchAssignedAccountsList,
    fetchAssignedAccountsListSuccess,
    fetchAssignedAccountsListFailure,
} from './actions';
import { ASSIGN_ACCOUNT, FETCH_ASSIGNED_ACCOUNTS_LIST } from './actions.types';
import { assignedAccountsReducerName, assignedAccountsListsPerLocationStoreKeyName } from './reducer';


export const onAssignAccount: Epic = (action$, state$, { businessUsers, i18n }) => action$.pipe(
    ofType(ASSIGN_ACCOUNT),
    switchMap(({ payload: { requestPayload, locationPathname } }) => {
        return from(businessUsers.assignAccount(requestPayload)).pipe(
            switchMap((response) => {
                const queryParams = state$.value[assignedAccountsReducerName][assignedAccountsListsPerLocationStoreKeyName]?.[locationPathname]?.queryParams;

                return of(
                    assignAccountSuccess(response.data),
                    hideModal(),
                    fetchAssignedAccountsList({ queryParams, locationPathname }),
                    showToast({
                        type: ToastType.success,
                        message: i18n.t('businessUsers:actionMessages.assignedAccountSuccess'),
                        id: 'assign-account',
                    }),
                );
            }),
            catchError((error) => {
                return of(
                    assignAccountFailure(),
                    showToast({ type: ToastType.error, message: `Assign Account: ${error.message}` }),
                );
            }),
        );
    }),
);


export const onFetchAssignedAccountsList: Epic = (action$, state$, { businessUsers }) => action$.pipe(
    ofType(FETCH_ASSIGNED_ACCOUNTS_LIST),
    switchMap(({ payload: { locationPathname, queryParams } }) => from(
        businessUsers.getAssignedAccountsList(queryParams.businessUserId),
    ).pipe(
        switchMap((response) => of(fetchAssignedAccountsListSuccess({
            items: response?.data?.rows,
            totalCount: response?.data?.count,
            locationPathname,
        }))),
        catchError((error) => {
            return of(
                fetchAssignedAccountsListFailure({ locationPathname }),
                showToast({ type: ToastType.error, message: `Fetch Assigned Account List: ${error.message}` }),
            );
        }),
    )),
);

export default [
    onFetchAssignedAccountsList,
    onAssignAccount,
];
