import { createAction } from '@reduxjs/toolkit';

import { FETCH_CARD_TIERS_LIST, FETCH_CARD_TIERS_LIST_SUCCESS, FETCH_CARD_TIERS_LIST_FAILURE, CLEAR_CARD_TIERS_LIST } from './action.types';


export const fetchCardTiersList = createAction(FETCH_CARD_TIERS_LIST, ({
    queryParams,
    locationPathname,
}) => ({
    payload: {
        queryParams,
        locationPathname,
    },
}));


export const fetchCardTiersListSuccess = createAction(
    FETCH_CARD_TIERS_LIST_SUCCESS,
    ({ items, totalCount, locationPathname }) => ({ payload: { items, totalCount, locationPathname } }),
);

export const fetchCardTiersListFailure = createAction(FETCH_CARD_TIERS_LIST_FAILURE, ({ locationPathname }) => ({ payload: { locationPathname } }));

export const clearCardTiersList = createAction(CLEAR_CARD_TIERS_LIST, ({ locationPathname }) => ({ payload: { locationPathname } }));
