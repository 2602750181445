import { createReducer } from '@reduxjs/toolkit';

import { SortDirections } from 'models/app/applicationState';

import { withTotalCount } from 'services/http/http.helpers';

import { SET_QUERY_PARAMS } from 'store/application/action.types';
import { CLEAR_CURRENT_USER } from 'store/current-user/actions.types';

import {
    ASSIGN_ACCOUNT,
    ASSIGN_ACCOUNT_SUCCESS,
    ASSIGN_ACCOUNT_FAILURE,
    FETCH_ASSIGNED_ACCOUNTS_LIST,
    FETCH_ASSIGNED_ACCOUNTS_LIST_SUCCESS,
    FETCH_ASSIGNED_ACCOUNTS_LIST_FAILURE,
    CLEAR_ASSIGNED_ACCOUNTS_LIST,
} from './actions.types';
import {
    updateStandardListDataQueryParams,
    updateStandardLists,
    updateStandardListsOnCleanup,
    updateStandardListsOnFailure,
    updateStandardListsOnSuccess,
} from '../standardLists.helpers';


export const assignedAccountsReducerName = 'assignedAccounts';
export const assignedAccountsListsPerLocationStoreKeyName = 'assignedAccountsListsPerLocation';

export const defaultAssignedAccountListQueryParams = {
    offset: 0,
    type: undefined,
    businessUserId: undefined,
    sortOrder: SortDirections.ASCEND,
    ...withTotalCount,
};

export const assignedAccountsInitialState = {
    [assignedAccountsListsPerLocationStoreKeyName]: {},
    isLoading: false,
};

const createAccountsBuilderCases = (builder) => builder
    .addCase(ASSIGN_ACCOUNT, (state) => {
        state.isLoading = true;
    })
    .addCase(ASSIGN_ACCOUNT_SUCCESS, (state) => {
        state.isLoading = false;
    })
    .addCase(ASSIGN_ACCOUNT_FAILURE, (state) => {
        state.isLoading = false;
    })
    .addCase(FETCH_ASSIGNED_ACCOUNTS_LIST, (state, action) => {
        state[assignedAccountsListsPerLocationStoreKeyName] = updateStandardLists({
            listsState: state[assignedAccountsListsPerLocationStoreKeyName],
            action,
        });
    })
    .addCase(FETCH_ASSIGNED_ACCOUNTS_LIST_SUCCESS, (state, action) => {
        state[assignedAccountsListsPerLocationStoreKeyName] = updateStandardListsOnSuccess({
            listsState: state[assignedAccountsListsPerLocationStoreKeyName],
            action,
        });
    })
    .addCase(FETCH_ASSIGNED_ACCOUNTS_LIST_FAILURE, (state, action) => {
        state[assignedAccountsListsPerLocationStoreKeyName] = updateStandardListsOnFailure({
            listsState: state[assignedAccountsListsPerLocationStoreKeyName],
            action,
        });
    })
    .addCase(CLEAR_ASSIGNED_ACCOUNTS_LIST, (state, action) => {
        state[assignedAccountsListsPerLocationStoreKeyName] = updateStandardListsOnCleanup({
            listsState: state[assignedAccountsListsPerLocationStoreKeyName],
            action,
        });
    })
    .addCase(SET_QUERY_PARAMS, (state, action) => {
        if (action.payload?.reducerName === assignedAccountsReducerName
            && action.payload?.fieldName === assignedAccountsListsPerLocationStoreKeyName
        ) {
            state[assignedAccountsListsPerLocationStoreKeyName] = updateStandardListDataQueryParams({
                listsState: state[assignedAccountsListsPerLocationStoreKeyName],
                action,
            });
        }
    })
    .addCase(CLEAR_CURRENT_USER, (state) => {
        state[assignedAccountsListsPerLocationStoreKeyName] = {};
    });


export default createReducer(assignedAccountsInitialState, createAccountsBuilderCases);
