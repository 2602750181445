import {
    getBusinessUserIdDetails,
    updateBusinessUserId,
    updateBusinessUserIdPassword,
    updateBusinessUserStatusAPI,
    getAssignedAccountsList,
    assignAccount,
} from './index';


class BusinessUsersService {
    public getBusinessUserDetails(businessUserId) {
        return getBusinessUserIdDetails(businessUserId);
    }

    public updateBusinessUser(requestPayload) {
        const { businessUserId, ...rest } = requestPayload;

        return updateBusinessUserId(businessUserId, rest);
    }

    public updateBusinessUserPassword(requestPayload) {
        const { businessUserId, ...rest } = requestPayload;

        return updateBusinessUserIdPassword(businessUserId, rest);
    }

    public updateBusinessUserStatus(businessUserId, requestPayload) {
        return updateBusinessUserStatusAPI(businessUserId, requestPayload);
    }


    public getAssignedAccountsList(businessUserId) {
        return getAssignedAccountsList(businessUserId);
    }

    public assignAccount(requestPayload) {
        const { businessUserId, ...rest } = requestPayload;

        return assignAccount(businessUserId, rest);
    }

}

export default BusinessUsersService;

