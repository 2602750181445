import { createAction } from '@reduxjs/toolkit';

import {
    ASSIGN_ACCOUNT,
    ASSIGN_ACCOUNT_SUCCESS,
    ASSIGN_ACCOUNT_FAILURE,
    FETCH_ASSIGNED_ACCOUNTS_LIST,
    FETCH_ASSIGNED_ACCOUNTS_LIST_SUCCESS,
    FETCH_ASSIGNED_ACCOUNTS_LIST_FAILURE,
    CLEAR_ASSIGNED_ACCOUNTS_LIST,
} from './actions.types';


export const assignAccount = createAction(ASSIGN_ACCOUNT, ({ requestPayload, locationPathname }) => ({ payload: { requestPayload, locationPathname } }));
export const assignAccountSuccess = createAction(ASSIGN_ACCOUNT_SUCCESS);
export const assignAccountFailure = createAction(ASSIGN_ACCOUNT_FAILURE);


export const fetchAssignedAccountsList = createAction(FETCH_ASSIGNED_ACCOUNTS_LIST, ({ queryParams, locationPathname }) => ({ payload: { queryParams, locationPathname } }));
export const fetchAssignedAccountsListSuccess = createAction(
    FETCH_ASSIGNED_ACCOUNTS_LIST_SUCCESS,
    ({ items, totalCount, locationPathname }) => ({ payload: { items, totalCount, locationPathname } }),
);
export const fetchAssignedAccountsListFailure = createAction(FETCH_ASSIGNED_ACCOUNTS_LIST_FAILURE, ({ locationPathname }) => ({ payload: { locationPathname } }));
export const clearAssignedAccountsList = createAction(CLEAR_ASSIGNED_ACCOUNTS_LIST, ({ locationPathname }) => ({ payload: { locationPathname } }));
