import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { Epic } from 'models/meta/epic';

import { FETCH_CARD_TIERS_LIST } from './action.types';
import { fetchCardTiersListFailure, fetchCardTiersListSuccess } from './actions';
import { addPartnerUuidToQueryParams } from '../common.helpers';


export const onFetchCardTiersList: Epic = (action$, state$, { cardTiers }) => action$.pipe(
    ofType(FETCH_CARD_TIERS_LIST),
    switchMap(({
        payload: {
            locationPathname,
            queryParams,
        },
    }) => from(cardTiers.fetchPartnerCardTiers(addPartnerUuidToQueryParams({ state$, queryParams }))).pipe(
        switchMap((response) => {
            return of(fetchCardTiersListSuccess({
                items: response.data,
                totalCount: response?.data?.length,
                locationPathname,
            }));
        }),
        catchError(() => {
            return of(fetchCardTiersListFailure({ locationPathname }));
        }),
    )),
);
