import { createReducer } from '@reduxjs/toolkit';

import { SortDirections } from 'models/app/applicationState';

import { standardPageSize } from 'config/config';

import { SET_QUERY_PARAMS } from 'store/application/action.types';
import {
    updateStandardListDataQueryParams,
    updateStandardLists,
    updateStandardListsOnCleanup,
    updateStandardListsOnFailure,
    updateStandardListsOnSuccess,
} from 'store/standardLists.helpers';

import {
    FETCH_CARD_TIERS_LIST,
    FETCH_CARD_TIERS_LIST_FAILURE,
    FETCH_CARD_TIERS_LIST_SUCCESS,
    CLEAR_CARD_TIERS_LIST,
} from './action.types';
import { CLEAR_CURRENT_USER } from '../current-user/actions.types';


export const cardTiersReducerName = 'cardTiers';
export const cardTiersListsPerLocationStoreKeyName = 'cardTiersListsPerLocation';

export const defaultCardTiersListQueryParams = {
    limit: standardPageSize,
    offset: 0,
    sortBy: 'createdAt',
    sortOrder: SortDirections.ASCEND,
};


export const cardTiersInitialListState = {
    [cardTiersListsPerLocationStoreKeyName]: {},
    loading: false,
};


const createBusinessUsersBuilderCases = (builder) =>
    builder
        .addCase(FETCH_CARD_TIERS_LIST, (state, action) => {
            state[cardTiersListsPerLocationStoreKeyName] = updateStandardLists(
                {
                    listsState: state[cardTiersListsPerLocationStoreKeyName],
                    action,
                },
            );
        })
        .addCase(FETCH_CARD_TIERS_LIST_SUCCESS, (state, action) => {
            state[cardTiersListsPerLocationStoreKeyName] = updateStandardListsOnSuccess(
                {
                    listsState: state[cardTiersListsPerLocationStoreKeyName],
                    action,
                },
            );
        })
        .addCase(FETCH_CARD_TIERS_LIST_FAILURE, (state, action) => {
            state[cardTiersListsPerLocationStoreKeyName] = updateStandardListsOnFailure({
                listsState: state[cardTiersListsPerLocationStoreKeyName],
                action,
            });
        })
        .addCase(CLEAR_CARD_TIERS_LIST, (state, action) => {
            state[cardTiersListsPerLocationStoreKeyName] = updateStandardListsOnCleanup({
                listsState: state[cardTiersListsPerLocationStoreKeyName],
                action,
            });
        })
        .addCase(SET_QUERY_PARAMS, (state, action) => {
            if (action.payload?.reducerName === cardTiersReducerName
                  && action.payload?.fieldName === cardTiersListsPerLocationStoreKeyName
            ) {
                state[cardTiersListsPerLocationStoreKeyName] = updateStandardListDataQueryParams({
                    listsState: state?.[cardTiersListsPerLocationStoreKeyName],
                    action,
                });
            }
        })
        .addCase(CLEAR_CURRENT_USER, () => {
            return cardTiersInitialListState;
        });


export default createReducer(cardTiersInitialListState, createBusinessUsersBuilderCases);
