import {
    jwtDecode,
    calculateTimeToSilentTokenRefresh, getJwt,
    isDateInTheFuture, saveJwt, setSessionStorageObjectItem,
    globalLocation,
} from '@manigo/manigo-commons';
import { UserDataFormToken, RawJwtToken } from '@manigo/manigo-domain-typings';
import { ofType } from 'redux-observable';
import { EMPTY, from, fromEvent, of, timer } from 'rxjs';
import {
    catchError, map, mergeMap, switchMap, takeUntil, tap,
} from 'rxjs/operators';

import { ToastType } from 'models/app/toast';
import { Epic } from 'models/meta/epic';

import { clientConfigKey, companyDetailsKey, defaultLocale, permissionsKey } from 'config/config';
import { authorisedNamespaces, onboardingNamespaces } from 'config/i18n';
import { accountsListRootRoutePath, loginRootRoutePath, publicRoutes, rootRoutePath } from 'config/routes';


import { getStoredLanguage } from 'utils/locales-tools';

import { APPLICATION_READY_AUTHORISED_USER } from 'store/application/action.types';
import { hideAutoLogoutBanner, removeJwtTokenFromStorage, showToast } from 'store/application/actions';
import { refreshToken } from 'store/authorisation/actions';
import { authorisationReducerName } from 'store/authorisation/reducer';
import { fetchCardTiersList } from 'store/card-tiers/actions';
import { fetchCompanyDetails } from 'store/company/actions';
import { fetchCountries } from 'store/countries/actions';
import { fetchCurrencies } from 'store/currencies/actions';
import {
    checkTokens,
    clearCurrentUser,
    expireToken,
    extendTokenValidity,
    extendTokenValidityFailure,
    extendTokenValiditySuccess,
    setCurrentUserFailure,
    setCurrentUserSuccess,
    updateCurrentUserPasswordFailure,
    updateCurrentUserPasswordSuccess,
    updateCurrentUserPreferencesFailure,
    updateCurrentUserPreferencesSuccess,
} from 'store/current-user/actions';
import { loadLanguage, loadNamespaces } from 'store/locales/actions';
import { hideModal } from 'store/modal/actions';
import { requestNavigation } from 'store/navigation/actions';
import { fetchProductsList } from 'store/products/actions';

import {
    CHECK_TOKENS,
    CLEAR_CURRENT_USER,
    EXPIRE_TOKEN,
    EXTEND_TOKEN_VALIDITY,
    EXTEND_TOKEN_VALIDITY_SUCCESS,
    SET_CURRENT_USER,
    SET_CURRENT_USER_FAILURE,
    SET_CURRENT_USER_SUCCESS,
    UPDATE_CURRENT_USER_PASSWORD,
    UPDATE_CURRENT_USER_PREFERENCES,
    USER_ACTIVITY,
} from './actions.types';
import SET_CURRENT_USER_VARIANT, { createChangeBusinessUserPasswordErrorMessage } from './epics.helpers';
import { currentUserReducerName } from './reducer';


export const onSetCurrentUser: Epic = (action$, _, { http }) => {
    return action$.pipe(
        ofType(SET_CURRENT_USER),
        tap(({ payload: { responsePayload: { permissions, configuration, company, ...rest } } }) => {
            http.setUserToken(rest.accessToken);
            setSessionStorageObjectItem(permissionsKey, permissions);
            setSessionStorageObjectItem(companyDetailsKey, company);
            setSessionStorageObjectItem(clientConfigKey, configuration);
            saveJwt(rest);
        }),
        mergeMap(({ payload: { responsePayload } }) => {
            if (responsePayload.accessToken) {
                const storedLanguage = getStoredLanguage();
                const decodedUserData: UserDataFormToken = jwtDecode(responsePayload.accessToken);
                const shouldChangeLanguage = decodedUserData.preferences.language !== storedLanguage;

                return of(
                    ...(shouldChangeLanguage ? [loadLanguage(decodedUserData.preferences.language || defaultLocale)] : []),
                    loadNamespaces([...onboardingNamespaces, ...authorisedNamespaces]),
                    setCurrentUserSuccess(responsePayload),
                );
            }
            return of(setCurrentUserFailure());
        }),
    );
};

export const onSetCurrentUserSuccess: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(SET_CURRENT_USER_SUCCESS),
        mergeMap(() => {
            const shouldMakeFetch = state$.value[currentUserReducerName].variant !== SET_CURRENT_USER_VARIANT.REFRESH_SESSION;

            return of(
                ...(shouldMakeFetch ? [
                    fetchCountries(),
                    fetchProductsList({
                        queryParams: { clientId: state$.value[currentUserReducerName]?.userData?.identifiers?.clientId },
                        locationPathname: globalLocation,
                    }),
                    fetchCurrencies(),
                    fetchCompanyDetails({ queryParams: { companyId: state$.value[currentUserReducerName]?.userData?.identifiers?.companyId } }),
                    fetchCardTiersList({ locationPathname: globalLocation }),
                ] : []),
            );
        }),
    );
};
export const onApplicationReadyAuthorisedUser: Epic = (action$, state$, { history }) => {
    return action$.pipe(
        ofType(APPLICATION_READY_AUTHORISED_USER),
        switchMap(() => {
            const { pathname } = history.location;
            const isPublic = publicRoutes.includes(pathname);
            const nextLocation = !isPublic
                ? pathname
                : accountsListRootRoutePath;

            return !isPublic
                ? EMPTY // XXX DO nothing -  all should be fine or if route is not found catch-all redirect to accountsListRootRoutePath will be preformed by router
                : of(requestNavigation({ locationPathname: nextLocation }));
        }),
    );
};


export const onSetCurrentUserFailure: Epic = (action$) => {
    return action$.pipe(
        ofType(SET_CURRENT_USER_FAILURE),
        switchMap(() => of(requestNavigation({ locationPathname: rootRoutePath }))),
    );
};


export const onClearCurrentUser: Epic = (action$, _, { http }) => {
    return action$.pipe(
        ofType(CLEAR_CURRENT_USER),
        tap(() => {
            http.clearUserToken();
        }),
        switchMap(() => of(
            expireToken(),
            removeJwtTokenFromStorage(),
            requestNavigation({ locationPathname: loginRootRoutePath }),
        )),
    );
};


export const onExtendTokenValidity: Epic = (action$, state$, { authorisation }) => {
    return action$.pipe(
        ofType(EXTEND_TOKEN_VALIDITY),
        switchMap(() => {
            const jwtTokens = getJwt();
            if (jwtTokens?.refreshToken) {
                return from(authorisation.extendTokenValidity(jwtTokens.refreshToken)).pipe(
                    switchMap((response) => {
                        return of(extendTokenValiditySuccess(response.data));
                    }),
                    catchError(() => of(extendTokenValidityFailure())),
                );
            }
            return of(extendTokenValidityFailure());
        }),
    );
};

export const onExtendTokenValiditySuccess = (action$, state$, { http }) => {
    return action$.pipe(
        ofType(EXTEND_TOKEN_VALIDITY_SUCCESS),
        tap(({ payload }) => {
            http.setUserToken(payload.accessToken);
            saveJwt(payload);
        }),
        switchMap(() => EMPTY),
    );
};

export const onExpireToken: Epic = (action$, state$, { authorisation }) => {
    return action$.pipe(
        ofType(EXPIRE_TOKEN),
        switchMap(() => {
            return from(authorisation.expireToken()).pipe(
                switchMap(() => EMPTY),
                catchError(() => EMPTY),
            );
        }),
    );
};

export const onReceivingToken: Epic = (action$) => {
    return action$.pipe(
        ofType(
            SET_CURRENT_USER,
            EXTEND_TOKEN_VALIDITY_SUCCESS,
        ),
        switchMap(({ payload }) => {
            const delay = calculateTimeToSilentTokenRefresh(payload.expirationTimeOfAccessToken || payload?.responsePayload?.expirationTimeOfAccessToken);
            return timer(delay).pipe(
                takeUntil(action$.pipe(ofType(CLEAR_CURRENT_USER))),
                map(() => extendTokenValidity()),
            );
        }),
    );
};

const checkTokensValidity = (state) => {
    const jwtToken: RawJwtToken = getJwt();
    const { isAutoLogoutBannerVisible } = state.application;

    if (isDateInTheFuture(jwtToken.expirationTimeOfAccessToken)) {
        return isAutoLogoutBannerVisible === true ? of(hideAutoLogoutBanner()) : EMPTY;
    }
    const { isRefreshingSession } = state[authorisationReducerName];

    if (jwtToken
        && !isDateInTheFuture(jwtToken.expirationTimeOfAccessToken)
        && isDateInTheFuture(jwtToken.expirationTimeOfRefreshToken)
    ) {
        return of(hideAutoLogoutBanner(), ...(isRefreshingSession ? [] : [refreshToken()]));
    }

    return of(clearCurrentUser());
};


export const onUserActivity: Epic = (action$) => {
    return action$.pipe(
        ofType(USER_ACTIVITY),
        switchMap(() => of(checkTokens('userActivityAfterIDLE'))),
    );
};


export const onDocumentVisibilityChange: Epic = (action$) => {
    return action$.pipe(
        ofType(SET_CURRENT_USER),
        switchMap(() => {
            return fromEvent(document, 'visibilitychange').pipe(
                takeUntil(action$.pipe(ofType(CLEAR_CURRENT_USER))),
                switchMap(() => of(checkTokens('document.visibilitychange'))),
            );
        }),
    );
};

export const onCheckTokens: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(CHECK_TOKENS),
        switchMap(() => checkTokensValidity(state$.value)),
    );
};


export const onUpdateCurrentUserPreferences: Epic = (action$, state$, {
    businessUsers,
    i18n,
}) => action$.pipe(
    ofType(UPDATE_CURRENT_USER_PREFERENCES),
    switchMap(({ payload }) => from(businessUsers.updateBusinessUser(payload))
        .pipe(switchMap((response) => {
            const isSelectedLangChanged = payload?.userPreferences?.language !== state$.value.currentUser?.userPreferences?.language;

            return of(
                refreshToken(),
                updateCurrentUserPreferencesSuccess(response.data),
                ...(isSelectedLangChanged ? [loadLanguage(payload?.userPreferences?.language)] : []),
                showToast({
                    type: ToastType.success,
                    message: i18n.t('businessUsers:actionMessages.updatePreferencesSuccess'),
                }),
                hideModal(),
            );
        }),
        catchError(() => of(updateCurrentUserPreferencesFailure())))),
);
export const onUpdateCurrentUserPassword: Epic = (action$, state$, {
    businessUsers,
    i18n,
}) => action$.pipe(
    ofType(UPDATE_CURRENT_USER_PASSWORD),
    switchMap(({ payload }) => from(businessUsers.updateBusinessUserPassword(payload))
        .pipe(switchMap((response) => {
            return of(
                updateCurrentUserPasswordSuccess(response.data),
                showToast({
                    type: ToastType.success,
                    message: i18n.t('businessUsers:actionMessages.changePasswordSuccess'),
                }),
                hideModal(),
                clearCurrentUser(),
            );
        }),
        catchError((error) => of(
            showToast({ type: ToastType.error, message: createChangeBusinessUserPasswordErrorMessage(i18n.t, error) }),
            updateCurrentUserPasswordFailure(),
        )))),
);


export default [
    onSetCurrentUser,
    onSetCurrentUserSuccess,
    onApplicationReadyAuthorisedUser,
    onSetCurrentUserFailure,
    onClearCurrentUser,
    onExtendTokenValidity,
    onExtendTokenValiditySuccess,
    onExpireToken,
    onReceivingToken,
    onUserActivity,
    onCheckTokens,
    onDocumentVisibilityChange,
    onUpdateCurrentUserPreferences,
    onUpdateCurrentUserPassword,
];

