import { JSX } from 'react';


export enum ModalName {
    // account
    ADD_ACCOUNT = 'ADD_ACCOUNT',
    UPDATE_ACCOUNT_NAME = 'UPDATE_ACCOUNT_NAME',

    // card
    REPORT_CARD_AS = 'REPORT_CARD_AS',
    UPDATE_CARD_SETTINGS = 'UPDATE_CARD_SETTINGS',
    UPDATE_CARD_LIMITS = 'UPDATE_CARD_LIMITS',
    ACTIVATE_CARD = 'ACTIVATE_CARD',
    CARD_SENSITIVE_DATA = 'CARD_SENSITIVE_DATA',

    // company
    UPDATE_COMPANY_DATA = 'UPDATE_COMPANY_DATA',

    // user
    CHANGE_PASSWORD = 'CHANGE_PASSWORD',
    UPDATE_USER_PREFERENCES = 'UPDATE_USER_PREFERENCES',
    EDIT_USER_DATA = 'EDIT_USER_DATA',
    UPDATE_BUSINESS_USER_STATUS = 'UPDATE_BUSINESS_USER_STATUS',

    // unauthorized
    TERMS_OF_SERVICE = 'TERMS_OF_SERVICE',
    PRIVACY_POLICY = 'PRIVACY_POLICY',

    // SCA
    SCA_CHALLENGE = 'SCA_CHALLENGE',
    ASSIGN_ACCOUNTS = 'ASSIGN_ACCOUNTS',
}

export interface ModalProps {
    title?: string,
    modalSize?: string,
    footer?: JSX.Element | string,
    data?: object,
    id?: string,
    additionalOnCloseAction?: () => void,
}

export interface ModalType {
    modalType: string | null;
}

export interface ModalState {
    open: boolean;
    modalType: ModalType['modalType'];
    modalProps: ModalProps;
}

export interface ShowModalPayload {
    modalType: ModalType['modalType'];
    modalProps: ModalProps;
}
