import { StateObservable } from 'redux-observable';

import { QueryParams } from 'models/app/common';


import { currentUserReducerName } from './current-user/reducer';
import { RootState } from './rootReducer';


export function addCompanyIdToQueryParams({
    state$,
    queryParams,
}: { state$: StateObservable<RootState>, queryParams: QueryParams | any }) {

    const { businessUserId, beneficiaryUuid, accountId, cardId } = queryParams;

    const companyId = state$.value[currentUserReducerName].userData?.identifiers?.companyId;

    return {
        ...queryParams,
        ...(accountId ? { accountId } : {}),
        ...(businessUserId ? { businessUserId } : {}),
        ...(cardId ? { cardId } : {}),
        ...(beneficiaryUuid ? { beneficiaryId: beneficiaryUuid } : {}),
        ...(!beneficiaryUuid && !beneficiaryUuid && !accountId && !cardId
            ? { companyId }
            : {}
        ),
    };
}


export function addPartnerUuidToQueryParams({
    state$,
    queryParams,
}: { state$: StateObservable<RootState>, queryParams: QueryParams | any }) {

    const partnerUuid = state$.value[currentUserReducerName].userData?.identifiers?.extClientUuid; // extClientUuid is equivalent to partnerUuidId

    return {
        ...queryParams,
        ...(partnerUuid ? { partnerUuid } : {}),
    };
}

export type ActionPayload <PayloadType> = {
    payload: PayloadType;
    meta?: any
};
